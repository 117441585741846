#status span.status {
  display: none;
  font-weight: bold;
}
span.status.complete {
  color: green;
}
span.status.incomplete {
  color: red;
}
#status.complete span.status.complete {
  display: inline;
}
#status.incomplete span.status.incomplete {
  display: inline;
}

.header {
  height: 80px;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 15px 50px 15px;
}
.player {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.controls {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  
}

.marginRight {
  margin-right: 15px;
}

.stillWatching {
  background-color: rgb(255, 255, 255);
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 7px solid red;
  border-radius: 10px;
  z-index: 5;
  width: 50%;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 0);
}



.warningText {
  margin-right: 10px !important;
  font-size: 38px;
}

.reactVideo{
  width: 308px !important;
  height: 208px !important;
}

.videoName {
  text-align: center;
}

.warningText {
  font-size: 15px;
}

.contactUs {
  margin-top: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .reactVideo{
    width: 752px !important;
    height: 439px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .reactVideo{
    width: 998px !important;
    height: 654px !important;
  }

  .warningText {
    font-size: 35px;
  }
}