.container {
    height: 100vh;
}

.header {
    height: 80px;
    display: flex;
    justify-content: center;
}

.youtube {
    width: 100%;

}

.videoResponsive {
    display: flex; 
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: black;
}

iframe {
    overflow: hidden;
    border: 0;
    top: 0;
    left: 0;
    width: 800%;
    height: 80%;
}