.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 20px 55px 20px;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ijeHeader {
    text-align: center;
    font-size: 45px !important;
}
.error {
    margin-top: 15px;
    margin-bottom: 15px;
    color: red;
}

.videoName {
    font-size: 28px;
    margin-bottom: 15px;
    max-width: 750px;
    text-align: center;
}

.videoDesc {
    margin-bottom: 25px;
    text-align: center;
}

.instructionsTitle {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    margin: 0px 0px 20px 0px;
}

.handout {
    margin: 0px 0px 20px 0px;
}
@media only screen and (min-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 20px 100px 20px;
    }
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ijeHeader {
        text-align: center;
        font-size: 35px !important;
    }

    .videoName {
        font-size: 75px;
    }
    .instructionsTitle {
        font-size: 25px;
        text-align: center;
        font-weight: bold;
        margin: 0px 0px 30px 0px;
        max-width: 700px;
    }
}